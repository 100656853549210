<template>
  <a-card title="" style="margin-top: 16px;">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <ve-histogram 
    :data="incomeData" 
    :legend="{show:false}"
    :height="heightStr"
    judge-width
    :grid="{right: '5%'}"
    :mark-line="markLine"
    :title="{
        text:`财务统计(元)（${incomeData.title?incomeData.title:'0'}）`,
        subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
        textStyle: {
            fontSize: 15,
        },
        subtextStyle: {
            fontSize: 12.5
        }
    }" 
    :toolbox="{
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            }"
    :settings="{label: { show: true, position: 'top'},yAxisType: ['KMB', 'KMB']}" 
    :extend="{
        yAxis:{
            splitLine: {
            lineStyle: {
                type: 'dotted'
            }
            }
        },
        series:{
            type:'bar',
            barMaxWidth: 10,
            itemStyle:{
                normal:{
                    color: function(params) {
                        return {
                            type: 'linear',
                            x:1,
                            y:1,
                            x2:0,
                            y2:0,
                            colorStops: [{
                                offset: 0,
                                color: '#effffb'
                                
                            }, {
                                offset: 1,                    
                                color: '#23d9ae'
                            }],
                        }
                    },
                    barBorderRadius:[30,30,0,0]
                }
            }
      },
      }" ></ve-histogram>
      
  </a-card>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Income',
  props:['loading','heightStr','start_date','end_date','incomeData'],

  data () {
    this.markLine = {
      symbol: ['none', 'none'], //去掉箭头
      data: [
        {
          name: '平均线',
          type: 'average'
        }
      ]
    }
    return {
    }
  },

}
</script>
