<template>
  <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-heatmap 
              :data="chartData" 
              :settings="chartSettings" 
              :visual-map="chartVisualMap" 
              :grid="chartGrid"
              :extend="{xAxis: {
                type: 'category',
                axisLabel: {
                    interval: 0,
                    rotate: 30
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                }
              },
              series: {
                  type: 'heatmap',
                  itemStyle: {
                      emphasis: {
                          shadowBlur: 10,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      },
                      borderWidth: 3,
                      borderColor: '#fff'
                  }
              }}"
              >
            </ve-heatmap>
        </div>
    </a-card>
</template>

<script>
  import moment from 'moment'
  export default {
    name:'heatmap',
    data () {
      this.chartSettings = {
          yAxisList: ['23:00','22:00','21:00','20:00','19:00','18:00','17:00','16:00','15:00','14:00','13:00','12:00','11:00','10:00','09:00','08:00','07:00','06:00','05:00','04:00','03:00','02:00','01:00','00:00']
      }
      this.chartGrid = {
        right: 10
      }
      this.chartVisualMap = {
        min: 1,
        max: 51,
        type: 'piecewise',
        orient:'horizontal',
        pieces: [{
                    gt: 10,   //大于3 小于等于6
                    lte: 30,
                    color: 'red'
                }, {
                    gt: 30,  //大于6
                    color: 'blue'
                }],
      }
      return {
        loading:false,
        chartData: {}
      }
    },
    created () {
      this.getData()
    },
    methods: {
      async getData() {
        let res = await this.$store.dispatch('cFollowHeatmapAction', {})
        this.chartData = res.data
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>