var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticStyle:{"margin-top":"16px"},attrs:{"title":""}},[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-histogram',{attrs:{"data":_vm.incomeData,"legend":{show:false},"height":_vm.heightStr,"judge-width":"","grid":{right: '5%'},"mark-line":_vm.markLine,"title":{
      text:("财务统计(元)（" + (_vm.incomeData.title?_vm.incomeData.title:'0') + "）"),
      subtext: _vm.start_date==_vm.end_date?("" + _vm.start_date):(_vm.start_date + " ~ " + _vm.end_date),
      textStyle: {
          fontSize: 15,
      },
      subtextStyle: {
          fontSize: 12.5
      }
  },"toolbox":{
              feature: {
              magicType: {},
              saveAsImage: {pixelRatio:2}
              }
          },"settings":{label: { show: true, position: 'top'},yAxisType: ['KMB', 'KMB']},"extend":{
      yAxis:{
          splitLine: {
          lineStyle: {
              type: 'dotted'
          }
          }
      },
      series:{
          type:'bar',
          barMaxWidth: 10,
          itemStyle:{
              normal:{
                  color: function(params) {
                      return {
                          type: 'linear',
                          x:1,
                          y:1,
                          x2:0,
                          y2:0,
                          colorStops: [{
                              offset: 0,
                              color: '#effffb'
                              
                          }, {
                              offset: 1,                    
                              color: '#23d9ae'
                          }],
                      }
                  },
                  barBorderRadius:[30,30,0,0]
              }
          }
    },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }