<template>
    <div class="briefing">
        <a-row :gutter="16">
            <a-col :lg="24" :xl="12">
                <div class="briefing-nav">
                    <h1>{{title}}</h1>
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='timer' @change='handleTime' style="width: 100px">
                                <a-select-option :value="0">今日</a-select-option>
                                <a-select-option :value="1">本周</a-select-option>
                                <a-select-option :value="2">近一周</a-select-option>
                                <a-select-option :value="3">本月</a-select-option>
                                <a-select-option :value="4">近一个月</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>

                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        
                    </a-form>
                </div>
                <a-row :gutter="16">
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <a-col v-for="(item,index) in list" :key="index" :span="12">
                        <div class="briefing-wrap" @click="toPath(item)" :class="item.border?'briefing-wrap-border':''">
                            <div class="briefing-wrap-label">
                                <a-icon :style="{fontSize:'24px'}" :type="item.icon" />
                                <span class="briefing-wrap-title">{{item.label}}</span>
                            </div>
                            <span class="briefing-wrap-sum">{{detailData[item.value]}}</span>
                        </div>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :lg="24" :xl="12">
                <culeCount v-if="isTab === 1" title="招生统计" :loading='loadingC' heightStr="300px"  :leads_data ='leads_data' :start_date='analysisTime.search.start_date' :end_date='analysisTime.search.end_date' />
                <analysisEdu v-if="isTab === 2" :loading='loadingC' heightStr="300px"  :leads_data ='leads_data' :start_date='analysisTime.search.start_date' :end_date='analysisTime.search.end_date' />
                <income v-if="isTab === 3" heightStr="300px" :loading='loadingI' :incomeData="incomeData" :start_date='analysisTime.search.start_date' :end_date='analysisTime.search.end_date' />
            </a-col>
        </a-row>
    </div>
</template>

<script>
    import moment from 'moment'
    import income from './income'
    import analysisEdu from './analysisEdu'
    import ranges from "@/common/mixins/ranges"
    import culeCount from '@/views/bi/market/clue/cule_count'

    export default {
        name:"briefing",
        inject: ['parent','routesMenus'],
        data() {
            return {
                loadingC:false,
                loadingI:false,
                searchParams:{
                    search:{
                        is_sub:0,
                        employee_id:undefined,
                        start_date:moment().format('YYYY-MM-DD'),
                        end_date:moment().format('YYYY-MM-DD')
                    }
                },
                paramsData:{},
                leads_data:{},
                incomeData: {},
                timer:0,
                analysisTime:{
                    search:{
                        start_date : moment().add(-7, 'days').format('YYYY-MM-DD'),
                        end_date : moment().format('YYYY-MM-DD')
                    }
                }
                
            }
        },
        props:['detailData','title','loading','list','listName','isTab'],
        components: {
            income,
            culeCount,
            analysisEdu,
        },
        mixins: [ ranges ],
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.rangesValue = [moment(),moment()]
            this.searchParams.search.is_sub = this.$ls.get('is_sub')
            if(this.isTab == 1 || this.isTab == 2){
                this.getLeads()
                // setInterval(this.getLeads, 60000)
            }else{
                // setInterval(this.getData, 60000)
                this.getData()
            }
            this.handleTime(this.$ls.get('dashboard_timer') || 0)
            // setInterval(this.toScreen, 60000)
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getLeads(){
                this.loadingC = true
                let obj = this.analysisTime
                obj.search.is_sub = this.searchParams.search.is_sub
                let res = await this.$store.dispatch(this.isTab == 1?'analysisMainpanelCrmAction':'analysisMainpanelEduAction', {data:obj})
                this.leads_data = res.data
                this.loadingC = false
            },
            async getData() {
                this.loadingI=true
                let obj = this.analysisTime
                obj.search.is_sub = this.searchParams.search.is_sub
                let res = await this.$store.dispatch('analysisMainpanelAction', {data:obj})
                this.incomeData = res.data
                this.loadingI=false
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleIsSub(e){
                this.toScreen()
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
                if(this.isTab == 1 || this.isTab == 2){
                    this.getLeads()
                }else{
                    this.getData()
                }
            },
            handleTime(e){
                this.timer = e
                this.$ls.set('dashboard_timer', e)
                if(e === 0){
                    this.searchParams.search.start_date = moment().format('YYYY-MM-DD')
                    this.searchParams.search.end_date = moment().format('YYYY-MM-DD')
                }else if(e === 1){
                    this.searchParams.search.start_date = moment().startOf('week').format('YYYY-MM-DD')
                    this.searchParams.search.end_date = moment().format('YYYY-MM-DD')
                }else if(e === 2){
                    this.searchParams.search.start_date = moment().add(-7, 'days').format('YYYY-MM-DD')
                    this.searchParams.search.end_date = moment().format('YYYY-MM-DD')
                }else if(e === 3){
                    this.searchParams.search.start_date = moment().startOf('month').format('YYYY-MM-DD')
                    this.searchParams.search.end_date = moment().format('YYYY-MM-DD')
                }else{
                    this.searchParams.search.start_date = moment().add(-30, 'days').format('YYYY-MM-DD')
                    this.searchParams.search.end_date = moment().format('YYYY-MM-DD')
                }
                this.toScreen()
            },
            toScreen(){
                this.parent[this.listName](this.searchParams)
            },
            toPath(item){
                if(item.path){
                    this.routesMenus.menusKey = item.keyPath.keyPath
                    this.routesMenus.handleChange(item.keyPath)
                    this.routesMenus.handleMenkeyChange([item.path])
                    let obj = {}
                    if(item.query){
                        obj = item.query
                    }
                    obj.start_date = this.searchParams.search.start_date
                    obj.end_date = this.searchParams.search.end_date
                    this.$router.push({path:item.path,query:obj})
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .briefing{
        background: #fff;
        margin-top: 15px;
        border-radius: 2px;
        padding: 0 15px;
        &-nav{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid #e8e8e8;
            h1{
                margin-bottom: 0px;
            }
            .ant-form-item{
                margin-right: 5px;
            }
        }
        &-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            cursor: pointer;
            &-label{
                display: flex;
                align-items: center;
                span{
                    margin-left: 15px;
                }
            }
            &-title{
                font-size: 16px;
            }
            &-sum{
                font-size: 24px;
                font-weight: bold;
                color: #00cca2;
            }
            &-border{
                border-bottom: 1px solid #e8e8e8;
            }
        }
        .ant-row{
            padding: 10px 0;
        }
        .ant-card-bordered{
            border: none;
        }
    }
</style>