<template>
  <div class="dashboard">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <!-- <statistics :data="statisticsData"/> -->
      <!-- <functions :data="functionData" /> -->
      <div style="flex:1">

      <!--   <a-row :gutter="16" style="margin-top: 16px;">
          <a-col :span="4">
            <a-card>
              <a-statistic
                title="Feedback"
                :value="11.28"
                :precision="2"
                suffix="%"
                :value-style="{ color: '#333333' }"
                style="margin-right: 50px"
              >
                <template #prefix>
                  <a-icon type="arrow-up" />
                </template>
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :span="4">
            <a-card>
              <a-statistic
                title="Idle"
                :value="9.3"
                :precision="2"
                suffix="%"
                class="demo-class"
                :value-style="{ color: '#333333' }"
              >
                <template #prefix>
                  <a-icon type="arrow-down" />
                </template>
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :span="4">
            <a-card>
            <a-statistic title="Feedback" :value="1128" style="margin-right: 50px">
              <template #suffix>
                <a-icon type="like" />
              </template>
            </a-statistic>
            </a-card>
          </a-col>
          <a-col :span="4">
            <a-card>
            <a-statistic title="总学员数" :value="93" class="demo-class">
              <template #suffix>
                <a-icon type="team" />
              </template>
            </a-statistic>
            </a-card>
          </a-col>

          <a-col :span="4">
            <a-card>
            <a-statistic title="Active Users" :value="112893" style="margin-right: 50px" />
            </a-card>
          </a-col>

          <a-col :span="4">
            <a-card>
            <a-statistic title="本月成单" :precision="2" :value="112893">
            </a-statistic>
            </a-card>
          </a-col>

        </a-row>

        <a-row :gutter="16" style="margin-top: 16px;">
          <a-col :span="4">
            <a-card>
              <a-statistic
                title="Feedback"
                :value="11.28"
                :precision="2"
                suffix="%"
                :value-style="{ color: '#333333' }"
                style="margin-right: 50px"
              >
                <template #prefix>
                  <a-icon type="arrow-up" />
                </template>
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :span="4">
            <a-card>
              <a-statistic
                title="Idle"
                :value="9.3"
                :precision="2"
                suffix="%"
                class="demo-class"
                :value-style="{ color: '#333333' }"
              >
                <template #prefix>
                  <a-icon type="arrow-down" />
                </template>
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :span="4">
            <a-card>
            <a-statistic title="Feedback" :value="1128" style="margin-right: 50px">
              <template #suffix>
                <a-icon type="like" />
              </template>
            </a-statistic>
            </a-card>
          </a-col>
          <a-col :span="4">
            <a-card>
            <a-statistic title="总学员数" :value="93" class="demo-class">
              <template #suffix>
                <a-icon type="team" />
              </template>
            </a-statistic>
            </a-card>
          </a-col>

          <a-col :span="4">
            <a-card>
            <a-statistic title="Active Users" :value="112893" style="margin-right: 50px" />
            </a-card>
          </a-col>

          <a-col :span="4">
            <a-card>
            <a-statistic title="本月成单" :precision="2" :value="112893">
            </a-statistic>
            </a-card>
          </a-col>


        </a-row> -->
        <briefing :isTab="1" v-if="customerRouter.path" :loading="loadingC" :detailData="crmData" :list="crmList" listName="getCrmData" title="招生简报" />
        <briefing :isTab="2" v-if="educationRouter.path" :loading="loadingE" :detailData="educationData" :list="educationList" listName="getEducationData" title="教务简报" />
        <briefing :isTab="3" v-if="financeRouter.path" :loading="loadingF" :detailData="financeData" :list="financeList" listName="getFinanceData" title="财务简报" />
        <!-- <income :data="incomeData" v-if="incomeData"/> -->
      </div>
      <div class="dashboard-right">
        <a-card style="width: 300px">
          <div class="dashboard-qrcode">
            <img src="http://i01.wedots.cn/2021/11/02/a223a5f1a44f98d67312c7e1b52754bd.png">
            <div class="dashboard-desc">
              <h1>专属客服</h1>
              <div>
                添加专属客服，在使用过程中遇到任何问题我都会帮你解决
              </div>
            </div>
          </div>
        </a-card>

        <a-card style="width: 300px">
          <div class="dashboard-tel">
            <h1>更新日志</h1>
            <div>
              <div v-for="(item,index) in upgradeList" :key="index" class="dashboard-tel-warp">
                {{item.upgrade_date}} 【{{item.upgrade_version}}】
              </div>
            </div>
            <div class="dashboard-tel-more" @click="topath">更多</div>
          </div>
        </a-card>
        
        <!-- <div>
          <heatmap/>
        </div> -->
      </div>
  </div>
</template>

<script>
import { parsingEmoji } from '@/utils/emjoymethod'
import '@/styles/emjoy.css'
// import statistics from './statistics'
// import functions from './functions'
import income from './income'
import heatmap from './heatmap'
import briefing from './briefing'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export default {
  name: 'Dashboard',
  components: {
    // statistics,
    // functions,
    income,
    heatmap,
    briefing
  },
  provide() {
    return {
        parent: this
    }
  },
  data() {
    return {
      loading:true,
      loadingC:false,
      loadingE:false,
      loadingF:false,
      statisticsData: [],
      functionData: [],
      upgradeList:[],
      incomeData: null,
      crmData:{},
      educationData:{},
      financeData:{},
      crmList:[
          {
              icon:'user',
              label:'邀约到访',
              value:'promise_attend_count',
              path:'/work/invite-today',
              border:1,
              keyPath:{
                key:0,
                keyPath:[0]
              }
          },
          {
              icon:'user',
              label:'新增线索',
              value:'leads_count',
              path:'/crm/clue',
              border:1,
              keyPath:{
                key:1,
                keyPath:[1]
              }
          },
          {
              icon:'user',
              label:'跟进统计',
              border:1,
              value:'follow_count',
              path:'/crm/follow',
              keyPath:{
                key:1,
                keyPath:[1]
              }
          },
          {
              icon:'user',
              label:'线索转化',
              path:'/crm/customer',
              border:1,
              value:'customer_count',
              keyPath:{
                key:1,
                keyPath:[1]
              }
          },
          {
              icon:'user',
              label:'签约成单',
              value:'contract_count',
              path:'/crm/contract',
              border:1,
              keyPath:{
                key:1,
                keyPath:[1]
              }
          },
          {
              icon:'user',
              label:'签约金额',
              value:'contract_amount',
              path:'/crm/contract',
              border:1,
              keyPath:{
                key:1,
                keyPath:[1]
              }
          },
      ],
      educationList:[
          {
              icon:'user',
              label:'开班统计',
              value:'open_class_count',
              border:1,
          },
          {
              icon:'user',
              label:'排课统计',
              path:'/education/mine/schedule',
              value:'open_course_count',
              border:1,
              keyPath:{
                key:3,
                keyPath:[3]
              }
          },
          {
              icon:'user',
              label:'排课学员',
              border:1,
              path:'/education/mine/absence',
              value:'having_course_student_count',
              keyPath:{
                key:3,
                keyPath:[3]
              }
          },
          {
              icon:'user',
              label:'到课学员',
              path:'/education/mine/absence',
              border:1,
              value:'attend_course_student_count',
              query:{ activeKey:'1' , book_status:'2'},
              keyPath:{ 
                key:3,
                keyPath:[3]
              }
          },
          {
              icon:'user',
              label:'请假学员',
              path:'/education/mine/absence',
              value:'leave_course_student_count',
              query:{ activeKey:'2' },
              keyPath:{
                key:3,
                keyPath:[3]
              }
          },
          {
              icon:'user',
              label:'课消金额',
              path:'/education/mine/absence',
              value:'course_class_revenue',
              keyPath:{
                key:3,
                keyPath:[3]
              }
          }
      ],
      financeList:[
          {
              icon:'user',
              label:'签约金额',
              path:'/finance/contract',
              value:'contract_total_amount',
              border:1,
              keyPath:{
                key:7,
                keyPath:[7]
              }
          },
          {
              icon:'user',
              label:'签约合同',
              path:'/finance/contract',
              value:'contract_total_count',
              border:1,
              keyPath:{
                key:7,
                keyPath:[7]
              }
          },
          {
              icon:'user',
              label:'退费金额',
              border:1,
              path:'/finance/refund',
              value:'contract_refund_amount',
              keyPath:{
                key:7,
                keyPath:[7]
              }
          },
          {
              icon:'user',
              label:'退费申请',
              border:1,
              path:'/finance/refund',
              value:'contract_refund_count',
              keyPath:{
                key:7,
                keyPath:[7]
              }
          },
          {
              icon:'user',
              label:'课消金额',
              path:'/finance/cost-times',
              value:'course_class_revenue',
              keyPath:{
                key:7,
                keyPath:[7]
              }
          },
          {
              icon:'user',
              label:'请假学员',
              path:'/finance/cost-times',
              value:'leave_course_student_count',
              keyPath:{
                key:7,
                keyPath:[7]
              }
          }
      ],
      financeRouter:this.$store.getters.financeRouter,
      educationRouter:this.$store.getters.educationRouter,
      customerRouter:this.$store.getters.customerRouter,
    }
  },
  created() {
    this.getData()
    this.getUpgradeLog()


    // setInterval(createData, 30000)
    // var node = document.getElementById('app');
    // htmlToImage.toJpeg(node, { quality: 0.35 })
    // .then(function (dataUrl) {
    //   console.log(dataUrl)
    //   // var img = new Image();
    //   // img.src = dataUrl;
    //   // document.body.appendChild(img);
    // })
    // .catch(function (error) {
    //   console.error('oops, something went wrong!', error);
    // });
  },

  methods: {
    convertText2Emoji(text){
        return parsingEmoji(text)
    },
    async getData() {
      // let res = await this.$store.dispatch('dashboardIndexAction', {})
      // this.statisticsData = res.data.cards
      // this.functionData = res.shortcut
      // this.incomeData = res.data.chart
      this.loading=false;
    },
    async getCrmData(obj) {
      if(this.customerRouter.path){
        this.loadingC=true
        let res = await this.$store.dispatch('dashboardIndexCrmAction', {data:obj})
        this.crmData = res.data
        this.loadingC=false
      }
    },
    async getEducationData(obj) {
      this.loadingE=true
      let res = await this.$store.dispatch('dashboardIndexEducationAction', {data:obj})
      this.educationData = res.data
      this.loadingE=false
    },
    async getFinanceData(obj) {
      this.loadingF=true
      let res = await this.$store.dispatch('dashboardIndexFinanceAction', {data:obj})
      this.financeData = res.data
      this.loadingF=false
    },
    async getUpgradeLog() {
      let res = await this.$store.dispatch('oaUpgradeLogAction', {"page": 1,"per-page": 10})
      .then(res=>{
        this.upgradeList = res.items
      })
    },
    topath(){
      this.$router.push({path:'/work/upgradeLog'})
    }
    
  }
}
</script>
<style lang="scss" scoped>
  .dashboard{
    display: flex;
    &-right{
      // width: 330px;
      // background: #fff;
      margin-top: 15px;
      margin-left: 20px;
      .ant-card{
        margin-bottom: 15px;
      }
    }
    &-qrcode{
      display: flex;
      img{
        width: 100px;
        height: 100px;
        margin-right: 10px;
      }
    }
    &-desc{
      div{
        font-size: 12.5px;
      }
    }
    &-tel{
      &-warp{
        display: flex;
        align-items: center;
      }
      &-num{
        font-weight: bold;
        // margin-left: 10px;
      }
      &-more{
        cursor: pointer;
      }
    }
  }
</style>
